<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline">
        <!-- <el-form-item label="商户类型名称">
          <el-input v-model="formInline.label" clearable placeholder="请输入商户类型名称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-refresh">刷新</el-button>
           <el-button type="primary" @click="addRow" icon="el-icon-plus">新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="label"
          label="商户类型名称">
        </el-table-column>
        <el-table-column
          prop="value"
          label="数据值">
        </el-table-column>
        <el-table-column
          prop="description"
          label="描述">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="备注信息">
        </el-table-column>
        
        <el-table-column
          label="操作"
          width="250">
          <template slot-scope="scope">
            <el-button @click="editRow(scope.row)" type="primary" size="mini">修改</el-button>
            <el-button @click="deleteRow(scope.row)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <el-dialog class="dialogForm" :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="900px" :before-close="beforeClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px" style="width: 94%;" :disabled="viewFlag">
        <el-form-item label="商户类型名称" prop="label">
          <el-input v-model="form.label" clearable placeholder="请输入商户类型名称"></el-input>
        </el-form-item>
        <el-form-item label="数据值" prop="value">
          <el-input v-model="form.value" oninput="value=value.replace(/[^\d]/g,'')" clearable placeholder="请输入数据值"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description" clearable placeholder="请输入描述"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" maxlength="3" oninput="value=value.replace(/[^\d]/g,'')" clearable placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remarks">
          <el-input v-model="form.remarks" clearable placeholder="请输入备注信息"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-show="!viewFlag">
        <el-button v-show="editType === 'add'" @click="resetForm('form')">重置</el-button>
        <el-button type="primary" @click="submitForm('form')">确定提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDictItem, updateDictItem, delDictItem } from '@/api/merchantManage'
import { sureMessageBox }　from '@/utils/common'
export default {
  name:"",
  components:{
  },
  props:{},
  data(){
    return {
      formInline: {
        label: ''
      },
      shopTypeId: '',
      tableData: [],
      title: '',
      dialogFormVisible: false,
      viewFlag: false, //是否为查看
      editType: '', //新增还是编辑
      form: {
        label: '',
        value: '',
        remarks: '',
        description: '',
        sort: ''
      },
      rules: {
        label: [
          { required: true, message: '请输入商户类型名称', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        value: [
          { required: true, message: '请输入数据值', trigger: 'blur' }
        ],
      },
      dictType: 'shop_type'
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getList();
  },
  computed:{},
  methods:{
    //获取列表
    getList(){
      getDictItem({
        label: this.formInline.label,
        type: this.dictType
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.tableData = res.detail;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    //自定义序号
    indexMethod(index) {
      return index+1;
    },
    //查询
    onSubmit(){
      this.getList();
    },
    //新增商户类型
    addRow(){
      this.title = "新增商户类型";
      this.viewFlag = false;
      this.editType = 'add';
      this.dialogFormVisible = true;
    },
    beforeClose(done){
      this.resetForm('form');
      done();
    },
    editRow(row) {
      console.log("row:",row);
      this.title = '修改商户类型'
      this.viewFlag = false;
      this.editType = 'update';
      this.dialogFormVisible = true;
      this.shopTypeId = row.id;
      this.form = {
        label: row.label,
        value: row.value,
        remarks: row.remarks,
        description: row.description,
        sort: row.sort
      };
    },
    viewRow(row) {
      this.editRow(row);
      this.title = "商户类型";
      this.viewFlag = true;
    },
    deleteRow(row){
      sureMessageBox('是否确认删除该条数据？').then(res=>{
        delDictItem({
          id: row.id,
          type: this.dictType
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    resetForm(formName) {
      this.form = {
        label: '',
        value: '',
        remarks: '',
        description: '',
        sort: ''
      }
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let shopTypeData = {
            label: this.form.label,
            value: this.form.value,
            remarks: this.form.remarks,
            description: this.form.description,
            sort: this.form.sort,
            type: this.dictType
          };
          if(this.editType === 'update'){
            //修改
            shopTypeData.id = this.shopTypeId;
            sureMessageBox('是否确认修改该条数据？').then(res=>{
              updateDictItem(shopTypeData).then(res=>{
                if(res.result == 200){
                  this.$message.success('修改成功')
                  this.getList();
                  this.dialogFormVisible = false;
                  this.resetForm('form');
                }else{
                  this.$message.warning(res.description)
                }
              })
            }).catch(err=>{
              console.log(err)
            })
          }else{
            //新增
            updateDictItem(shopTypeData).then(res=>{
              if(res.result == 200){
                this.$message.success('添加成功')
                this.currentPage = 1;
                this.getList();
                this.dialogFormVisible = false;
                this.resetForm('form');
              }else{
                this.$message.warning(res.description)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .searchbox {
    margin: 20px 10px;
  }

  .pagebox{
    margin: 20px 0;
    text-align: right;
  }
</style>